import React, { useEffect, useState } from 'react';
import { Spinner } from '@components/ui-kit/spinner';
import { useGetInstagramReelTagsQuery } from '@store/instagram';
import { useGetYoutubeVideosTagsQuery } from '@store/youtube';
import { useGetTiktokPostsTagsQuery } from '@store/tiktok';
import { DropDownBtn } from '../drop-down-btn';
import { IVideoPost } from '@store/models';
import { EPlatform } from '@models/platform.enum';

export const TagsCell: React.FC<any> = ({ ...props }) => {
  const [tagsArr, setTagsArr] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  let styles = props.style;
  let classNames = props.className;
  const postId = props.dataItem.postId;
  const platform = props.dataItem.platform;

  const TiktokData: IVideoPost = props.TiktokData?.find(
    (post: IVideoPost) => post.id === postId,
  );
  const InstagramData: IVideoPost = props.InstagramData?.find(
    (reel: IVideoPost) => reel.id === postId,
  );
  const YoutubeData: IVideoPost = props.YoutubeData?.find(
    (video: IVideoPost) => video.id === postId,
  );

  const { data: InstagramDataTags = [], isLoading: isInstagramLoading } =
    useGetInstagramReelTagsQuery(null);

  const { data: TiktokDataTags = [], isLoading: isTiktokLoading } =
    useGetTiktokPostsTagsQuery(null);

  const { data: YoutubeDataTags = [], isLoading: isYoutubeLoading } =
    useGetYoutubeVideosTagsQuery(null);

  useEffect(() => {
    if (platform.toLowerCase() === EPlatform[EPlatform.tiktok]) {
      setTagsArr(TiktokDataTags);
      setLoading(isTiktokLoading);
    } else if (platform.toLowerCase() === EPlatform[EPlatform.instagram]) {
      setTagsArr(InstagramDataTags);
      setLoading(isInstagramLoading);
    } else if (platform.toLowerCase() === EPlatform[EPlatform.youtube]) {
      setTagsArr(YoutubeDataTags);
      setLoading(isYoutubeLoading);
    }
  }, [
    platform,
    isTiktokLoading,
    isInstagramLoading,
    isYoutubeLoading,
    TiktokDataTags,
    InstagramDataTags,
    YoutubeDataTags,
  ]);

  return (
    <td style={{ textAlign: 'center', ...styles }} className={classNames}>
      {loading ? (
        <Spinner />
      ) : (
        <DropDownBtn
          postId={postId}
          tagsArr={tagsArr}
          TiktokDataTags={TiktokData?.tags}
          InstagramDataTags={InstagramData?.tags}
          YoutubeDataTags={YoutubeData?.tags}
          platform={platform}
        />
      )}
    </td>
  );
};
