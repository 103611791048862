import React, { useEffect, useState } from 'react';
import {
  TreeList,
  orderBy,
  mapTree,
  extendDataItem,
  TreeListColumnProps,
  TreeListExpandChangeEvent,
  TreeListSortChangeEvent,
} from '@progress/kendo-react-treelist';

import { SortDescriptor } from '@progress/kendo-data-query';
import { IComment } from '@store/models';
import { useGetTiktokCommentByIdQuery } from '@store/tiktok';
import { useGetInstagramCommentByIdQuery } from '@store/instagram';
import {
  useMatch,
  useOutletContext,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { CommentCell, DateCell, ImgRowCell } from './models';
import { Spinner } from '../spinner';
import { Popup } from '@progress/kendo-react-popup';
import { TikTokEmbed } from 'react-social-media-embed';

import './styles.scss';
import { useGetYoutubeCommentByIdQuery } from '@store/youtube';

const subItemsField: string = 'comments';
const expandField: string = 'expanded';

interface AppState {
  data: IComment[];
  sort: SortDescriptor[];
  expanded: string[];
}

type PropsType = {
  startDate: string;
  endDate: string;
  post_id: any;
  setSearchParams: any;
};

export const CommentsTree: React.FC = () => {
  const { startDate, endDate, setSearchParams } =
    useOutletContext() as PropsType;
  const [commentsData, setCommentsData] = useState<IComment[]>();
  const [commentsLoading, setCommentsLoading] = useState<boolean>(true);
  const [currentLink, setCurrentLink] = useState<string>('');
  const [show, setShow] = React.useState(false);
  const anchor = React.useRef(null);
  const post_id = localStorage.getItem('post_id');

  const isTiktok = useMatch('/tiktok/comments-tree');
  const isInstagram = useMatch('/instagram/comments-tree');
  const isYoutube = useMatch('/youtube/comments-tree');

  const { data: tiktokComments = [], isLoading: tiktokCommentsLoading } =
    useGetTiktokCommentByIdQuery(
      startDate &&
        endDate &&
        post_id && {
          start: startDate,
          end: endDate,
          post_id: post_id,
        },
    );
  const { data: instagramComments = [], isLoading: instagramCommentsLoading } =
    useGetInstagramCommentByIdQuery(
      startDate &&
        endDate &&
        post_id && {
          start: startDate,
          end: endDate,
          post_id: post_id,
        },
    );

  const { data: youtubeComments = [], isLoading: youtubeCommentsLoading } =
    useGetYoutubeCommentByIdQuery(
      startDate &&
        endDate &&
        post_id && {
          start: startDate,
          end: endDate,
          post_id: post_id,
        },
    );

  useEffect(() => {
    if (isTiktok && tiktokComments && !tiktokCommentsLoading) {
      setCommentsData(tiktokComments);
      setCommentsLoading(tiktokCommentsLoading);
    }
    if (isInstagram && instagramComments && !instagramCommentsLoading) {
      setCommentsData(instagramComments);
      setCommentsLoading(instagramCommentsLoading);
    }
    if (isYoutube && youtubeComments && !youtubeCommentsLoading) {
      setCommentsData(youtubeComments);
      setCommentsLoading(youtubeCommentsLoading);
    }
  }, [
    instagramComments,
    instagramCommentsLoading,
    isInstagram,
    isTiktok,
    isYoutube,
    youtubeComments,
    youtubeCommentsLoading,
    tiktokComments,
    tiktokCommentsLoading,
  ]);

  useEffect(() => {
    if (commentsData) {
      setState(prevState => ({
        ...prevState,
        data: [...commentsData],
        expanded: [
          commentsData.length > 0
            ? commentsData[0]._id
              ? commentsData[0]._id
              : ''
            : '',
        ],
      }));
    }
  }, [commentsData]);

  useEffect(() => {
    const newSearchParams = new URLSearchParams({
      start: startDate,
      end: endDate,
      post_id: '' + post_id,
    });
    setSearchParams(newSearchParams);
  }, [startDate, endDate, setSearchParams, post_id]);

  const [state, setState] = React.useState<AppState>({
    data: commentsData ? [...commentsData] : [],
    sort: [{ field: 'post_id', dir: 'asc' }],
    expanded: [
      commentsData ? (commentsData.length > 0 ? commentsData[0]._id : '') : '',
    ],
  });

  const onExpandChange = (e: TreeListExpandChangeEvent) => {
    setState({
      ...state,
      expanded: e.value
        ? state.expanded.filter(id => id !== e.dataItem.id)
        : [...state.expanded, e.dataItem.id],
    });
  };

  const handleSortChange = (event: TreeListSortChangeEvent) => {
    setState({
      ...state,
      sort: event.sort,
    });
  };

  const addExpandField = (dataTree: IComment[]) => {
    const expanded: string[] = state.expanded;
    return mapTree(dataTree, subItemsField, (item: { id: string }) =>
      extendDataItem(item, subItemsField, {
        [expandField]: expanded.includes(item.id),
      }),
    );
  };

  const PopupHandler = () => {
    setShow(!show);
  };

  const processData = () => {
    const { data, sort } = state;
    return addExpandField(orderBy(data, sort, subItemsField));
  };

  const ImgCell = (props: any) => (
    <ImgRowCell
      {...{
        ...props,
        currentLink: currentLink,
        setCurrentLink: setCurrentLink,
        onClick: PopupHandler,
      }}
    />
  );

  const PopUp: React.FC<any> = ({ show, anchor, currentLink }) => {
    const isTiktok = useMatch('/tiktok/comments-tree');

    return (
      <Popup show={show} anchor={anchor.current}>
        {isTiktok && <TikTokEmbed url={currentLink} />}
      </Popup>
    );
  };

  const columns: TreeListColumnProps[] = [
    {
      field: 'title',
      title: 'title',
      width: '150px',
      expandable: true,
    },
    {
      field: 'post_id',
      title: 'Image',
      width: '80px',
      cell: ImgCell,
    },
    {
      field: 'data.create_time',
      title: 'Date',
      width: '160px',
      cell: DateCell,
    },
    {
      field: 'data.user.nickname',
      title: 'Nickname',
      width: '160px',
    },
    {
      field: 'data',
      title: 'Comments',
      expandable: true,
      cell: props => <CommentCell {...props} desc={processData()} />,
    },
    { field: 'Like', title: 'Like', width: '10px' },
    {
      field: 'reply',
      title: 'Reply',
      width: '120px',
      cell: props => {
        let firstReplyText = '';
        if (isYoutube) {
          if (
            Array.isArray(props.dataItem.reply) &&
            props.dataItem.reply.length > 0
          ) {
            firstReplyText = props.dataItem.reply
              .map(
                (reply: { snippet: { textDisplay: any } }) =>
                  reply.snippet.textDisplay,
              )
              .join(' ');
          }
        } else if (isTiktok) {
          firstReplyText =
            Array.isArray(props.dataItem.reply) &&
            props.dataItem.reply.length > 0
              ? props.dataItem.reply[0].text
              : '';
        } else if (isInstagram) {
          if (
            props.dataItem.data &&
            props.dataItem.data.replies &&
            props.dataItem.data.replies.data
          ) {
            firstReplyText = props.dataItem.data.replies.data
              .map((data: { text: any }) => data.text)
              .join(' ');
            console.log();
          } else {
            console.error('No replies or invalid data structure');
            firstReplyText = '';
          }
        }

        return <td>{firstReplyText}</td>;
      },
    },
    { field: 'Answers', title: 'Answers', width: '120px' },
    { field: 'Answers AI', title: 'Answers AI', width: '120px' },
  ];

  return (
    <>
      {commentsLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="anchor" ref={anchor}></div>
          <PopUp
            show={show}
            PopupHandler={PopupHandler}
            anchor={anchor}
            currentLink={currentLink}
          />
          <TreeList
            style={{ maxHeight: '510px', overflow: 'auto' }}
            expandField={expandField}
            subItemsField={subItemsField}
            onExpandChange={onExpandChange}
            sortable={true}
            sort={state.sort}
            data={processData()}
            onSortChange={handleSortChange}
            columns={columns}
          />
        </>
      )}
    </>
  );
};
