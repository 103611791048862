import React, { useState } from 'react';
import HoveredImage from './hovered-image';
import { EPlatform } from '@models/platform.enum';
const defaultImageUrl = '/img/defaultImage.webp';

export const ImgCell: React.FC<any> = ({ ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  let styles = props.style;
  let classNames = props.className;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const srtVideo = async () => {
    props.onClick();
    props.setCurrentLink(props.dataItem.videoUrl);
  };

  const getPlatformIcon = () => {
    switch (props.dataItem.platform.toLowerCase()) {
      case EPlatform[EPlatform.tiktok]:
        return <img src="tiktok.svg" alt="TikTok" className="platform-icon" />;
      case EPlatform[EPlatform.instagram]:
        return (
          <img src="instagram.svg" alt="Instagram" className="platform-icon" />
        );
      case EPlatform[EPlatform.youtube]:
        return (
          <img src="youtube.svg" alt="YouTube" className="platform-icon" />
        );
      default:
        return null;
    }
  };

  return (
    <td
      style={{ ...styles }}
      className={classNames}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="inform-table__img-wrapper">
        <img
          onClick={srtVideo}
          src={props.dataItem.imageUrl}
          alt={props.dataItem.title}
          onError={e => {
            e.currentTarget.src = defaultImageUrl;
          }}
        />
        {getPlatformIcon()}
      </div>
      {isHovered && (
        <HoveredImage
          imageUrl={props.dataItem.imageUrl}
          alt={props.dataItem.title}
          onClose={() => setIsHovered(false)}
        />
      )}
    </td>
  );
};
