import { configureStore } from '@reduxjs/toolkit';
import {
  tiktokCommentsAPI,
  tiktokConnectAPI,
  tiktokDailyStatsAPI,
  tiktokPostsAPI,
} from './tiktok';
import {
  instagramCommentsAPI,
  instagramConnectAPI,
  instagramDailyStatsAPI,
  instagramReelsAPI,
} from './instagram';
import {
  youtubeCommentsAPI,
  youtubeConnectAPI,
  youtubeDailyStatsAPI,
  youtubeVideosAPI,
} from './youtube';

import { usersAPI } from './user';
import { authAPI } from './auth';
import userReducer from './user/user-slice';
import { parserHistoryAPI } from './parser-history';
import { assistantAPI } from './assistant';
import { downloadAPI } from './download';
import { videoDailyStatsAPI, videoPostsAPI } from './video';

export const store = configureStore({
  reducer: {
    [tiktokDailyStatsAPI.reducerPath]: tiktokDailyStatsAPI.reducer,
    [tiktokPostsAPI.reducerPath]: tiktokPostsAPI.reducer,
    [tiktokCommentsAPI.reducerPath]: tiktokCommentsAPI.reducer,
    [tiktokConnectAPI.reducerPath]: tiktokConnectAPI.reducer,
    [instagramDailyStatsAPI.reducerPath]: instagramDailyStatsAPI.reducer,
    [instagramReelsAPI.reducerPath]: instagramReelsAPI.reducer,
    [instagramCommentsAPI.reducerPath]: instagramCommentsAPI.reducer,
    [instagramConnectAPI.reducerPath]: instagramConnectAPI.reducer,
    [youtubeConnectAPI.reducerPath]: youtubeConnectAPI.reducer,
    [youtubeDailyStatsAPI.reducerPath]: youtubeDailyStatsAPI.reducer,
    [assistantAPI.reducerPath]: assistantAPI.reducer,
    [youtubeVideosAPI.reducerPath]: youtubeVideosAPI.reducer,
    [youtubeCommentsAPI.reducerPath]: youtubeCommentsAPI.reducer,
    [usersAPI.reducerPath]: usersAPI.reducer,
    [authAPI.reducerPath]: authAPI.reducer,
    [downloadAPI.reducerPath]: downloadAPI.reducer,
    [parserHistoryAPI.reducerPath]: parserHistoryAPI.reducer,
    [videoDailyStatsAPI.reducerPath]: videoDailyStatsAPI.reducer,
    [videoPostsAPI.reducerPath]: videoPostsAPI.reducer,
    user: userReducer,
  },
  middleware: (
    getDefaultMiddleware: (arg0: {
      serializableCheck: boolean;
      immutableCheck: boolean;
    }) => any,
  ) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
    tiktokDailyStatsAPI.middleware,
    tiktokPostsAPI.middleware,
    tiktokCommentsAPI.middleware,
    tiktokConnectAPI.middleware,
    instagramDailyStatsAPI.middleware,
    instagramReelsAPI.middleware,
    instagramCommentsAPI.middleware,
    instagramConnectAPI.middleware,
    parserHistoryAPI.middleware,
    youtubeConnectAPI.middleware,
    assistantAPI.middleware,
    youtubeDailyStatsAPI.middleware,
    youtubeVideosAPI.middleware,
    youtubeCommentsAPI.middleware,
    usersAPI.middleware,
    authAPI.middleware,
    downloadAPI.middleware,
    videoDailyStatsAPI.middleware,
    videoPostsAPI.middleware,
  ],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
