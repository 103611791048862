import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { YOUTUBE_COMMENTS } from '@core/config/const';
export const youtubeCommentsAPI = createApi({
  reducerPath: YOUTUBE_COMMENTS,
  tagTypes: ['Youtube_comments'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getYoutubeCommentById: builder.query<any, any>({
      query: params => ({
        url: YOUTUBE_COMMENTS,
        params: {
          ...params,
          platform: 'youtube',
        },
      }),
      providesTags: ['Youtube_comments'],
    }),
    getYoutubeComments: builder.query<any, any>({
      query: params => ({
        url: `${YOUTUBE_COMMENTS}/all`,
        params: {
          ...params,
          platform: 'youtube',
        },
      }),
      providesTags: ['Youtube_comments'],
    }),
    postYoutubeComment: builder.mutation<any, any>({
      query: params => ({
        url: `${YOUTUBE_COMMENTS}/post`,
        params: {
          ...params,
          platform: 'youtube',
        },
      }),
      invalidatesTags: ['Youtube_comments'],
    }),
  }),
});

export const {
  useGetYoutubeCommentByIdQuery,
  useGetYoutubeCommentsQuery,
  usePostYoutubeCommentMutation,
} = youtubeCommentsAPI;
