import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY, IVideoPost } from '../models';
import { TIKTOK_POST, TIKTOK_POSTS, TIKTOK_POSTS_TAGS } from '@core/config';

export const tiktokPostsAPI = createApi({
  reducerPath: TIKTOK_POSTS,
  tagTypes: ['Tiktok_posts'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getTiktokPosts: builder.query<IVideoPost[], any>({
      query: params => ({
        url: TIKTOK_POSTS,
        params: {
          platform: 'tiktok',
          ...params,
        },
      }),
      providesTags: ['Tiktok_posts'],
    }),

    getTiktokPostsArrById: builder.query<IVideoPost[], any>({
      query(id) {
        return { url: `${TIKTOK_POSTS}/${id}`, params: { platform: 'tiktok' } };
      },
      providesTags: ['Tiktok_posts'],
    }),

    getTiktokPost: builder.query({
      query(id) {
        return { url: `${TIKTOK_POST}/${id}`, params: { platform: 'tiktok' } };
      },
      providesTags: ['Tiktok_posts'],
    }),

    getTiktokPostsTags: builder.query<string[], any>({
      query: params => ({
        url: TIKTOK_POSTS_TAGS,
        params: {
          ...params,
          platform: 'tiktok',
        },
      }),
      providesTags: ['Tiktok_posts'],
    }),

    updateTiktokPostTag: builder.mutation<IVideoPost, any>({
      query({ id, data }) {
        return {
          url: `${TIKTOK_POSTS_TAGS}/${id}`,
          method: 'PATCH',
          body: data,
          params: { platform: 'tiktok' },
        };
      },
      invalidatesTags: ['Tiktok_posts'],
    }),
  }),
});

export const {
  useGetTiktokPostsQuery,
  useGetTiktokPostQuery,
  useGetTiktokPostsArrByIdQuery,
  useGetTiktokPostsTagsQuery,
  useUpdateTiktokPostTagMutation,
} = tiktokPostsAPI;
