import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY, IVideoPost } from '../models';
import {
  YOUTUBE_VIDEO,
  YOUTUBE_VIDEOS,
  YOUTUBE_VIDEOS_TAGS,
} from '@core/config';

export const youtubeVideosAPI = createApi({
  reducerPath: YOUTUBE_VIDEOS,
  tagTypes: ['Youtube_videos'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getYoutubeVideos: builder.query<IVideoPost[], any>({
      query: params => ({
        url: YOUTUBE_VIDEOS,
        params: {
          ...params,
          platform: 'youtube',
        },
      }),
      providesTags: ['Youtube_videos'],
    }),

    getYoutubeVideosArrById: builder.query<IVideoPost[], any>({
      query(id) {
        return {
          url: `${YOUTUBE_VIDEOS}/${id}`,
          params: { platform: 'youtube' },
        };
      },
      providesTags: ['Youtube_videos'],
    }),

    getTYoutubeVideo: builder.query({
      query(id) {
        return {
          url: `${YOUTUBE_VIDEO}/${id}`,
          params: { platform: 'youtube' },
        };
      },
      providesTags: ['Youtube_videos'],
    }),

    getYoutubeVideosTags: builder.query<string[], any>({
      query: params => ({
        url: YOUTUBE_VIDEOS_TAGS,
        params: {
          ...params,
          platform: 'youtube',
        },
      }),
      providesTags: ['Youtube_videos'],
    }),

    updateYoutubeVideoTag: builder.mutation<IVideoPost, any>({
      query({ id, data }) {
        return {
          url: `${YOUTUBE_VIDEOS_TAGS}/${id}`,
          method: 'PATCH',
          body: data,
          params: { platform: 'youtube' },
        };
      },
      invalidatesTags: ['Youtube_videos'],
    }),
  }),
});

export const {
  useGetYoutubeVideosQuery,
  useGetYoutubeVideosArrByIdQuery,
  useGetTYoutubeVideoQuery,
  useGetYoutubeVideosTagsQuery,
  useUpdateYoutubeVideoTagMutation,
} = youtubeVideosAPI;
