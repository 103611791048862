import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { TIKTOK_COMMENTS } from '@core/config/const';
export const tiktokCommentsAPI = createApi({
  reducerPath: TIKTOK_COMMENTS,
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getTiktokCommentById: builder.query<any, any>({
      query: params => ({
        url: TIKTOK_COMMENTS,
        params: {
          ...params,
          platform: 'tiktok',
        },
      }),
    }),
    getTiktokComments: builder.query<any, any>({
      query: params => ({
        url: `${TIKTOK_COMMENTS}/all`,
        params: {
          ...params,
          platform: 'tiktok',
        },
      }),
    }),
    postTiktokComment: builder.mutation<any, any>({
      query: params => ({
        url: `${TIKTOK_COMMENTS}/post`,
        params: {
          ...params,
          platform: 'tiktok',
        },
      }),
    }),
  }),
});

export const {
  useGetTiktokCommentByIdQuery,
  useGetTiktokCommentsQuery,
  usePostTiktokCommentMutation,
} = tiktokCommentsAPI;
