import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY, IVideoPost } from '../models';
import {
  INSTAGRAM_REEL,
  INSTAGRAM_REELS,
  INSTAGRAM_REELS_TAGS,
} from '@core/config';

export const instagramReelsAPI = createApi({
  reducerPath: INSTAGRAM_REELS,
  tagTypes: ['Instagram_reels'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getInstagramReels: builder.query<IVideoPost[], any>({
      query: params => ({
        url: INSTAGRAM_REELS,
        params: {
          ...params,
          platform: 'instagram',
        },
      }),
      providesTags: ['Instagram_reels'],
    }),

    getInstagramReelsArrById: builder.query<IVideoPost[], any>({
      query(id) {
        return {
          url: `${INSTAGRAM_REELS}/${id}`,
          params: { platform: 'instagram' },
        };
      },
      providesTags: ['Instagram_reels'],
    }),

    getInstagramReel: builder.query({
      query(id) {
        return {
          url: `${INSTAGRAM_REEL}/${id}`,
          params: { platform: 'instagram' },
        };
      },
      providesTags: ['Instagram_reels'],
    }),

    getInstagramReelTags: builder.query<string[], any>({
      query: params => ({
        url: INSTAGRAM_REELS_TAGS,
        params: {
          ...params,
          platform: 'instagram',
        },
      }),
      providesTags: ['Instagram_reels'],
    }),

    updateInstagramReelTag: builder.mutation<IVideoPost, any>({
      query({ id, data }) {
        return {
          url: `${INSTAGRAM_REELS_TAGS}/${id}`,
          method: 'PATCH',
          body: data,
          params: { platform: 'instagram' },
        };
      },
      invalidatesTags: ['Instagram_reels'],
    }),
  }),
});

export const {
  useGetInstagramReelsQuery,
  useGetInstagramReelsArrByIdQuery,
  useGetInstagramReelQuery,
  useGetInstagramReelTagsQuery,
  useUpdateInstagramReelTagMutation,
} = instagramReelsAPI;
