import { TIKTOK_LINK, CHANNEL_NAME } from '@core/config';
import { ECoefficient } from '@models/coefficient.enum';
import { IDailyStats } from '@store/models/daily-stats.interface';
import moment from 'moment';
import numbro from 'numbro';

import {
  IVideoDailyStats,
  IVideoPost,
} from '@store/models/video-post.interfaces';
import { EPlatform } from '@models/platform.enum';

export interface IFormatData {
  posts: IVideoPost[];
  postsData: IVideoDailyStats[];
}

export const formatData = async ({
  posts,
  postsData,
}: IFormatData): Promise<IDailyStats[]> => {
  const defaultImageUrl = '/img/defaultImage.webp';

  const stats: IDailyStats[] | any = await Promise.all(
    postsData.map(async post => {
      let postData = posts.find(el => el.id === post?.post_id);

      if (!postData) {
        return null;
      }

      let platform = postData.platform || post.platform;
      let postId = postData.id;
      let title = `${postData.description || ''} --- ${postData?.platform}`;
      let imageUrl = postData.cover_url || defaultImageUrl;
      let videoUrl = postData.video_url || '';
      let followers = Math.round(post.followers);
      let sec = postData.duration || 0;

      let er =
        post.play_count > 0
          ? (
              ((post.digg_count +
                post.comment_count +
                post.share_count +
                post.followers) /
                post.play_count) *
              100
            ).toFixed(2) + '%'
          : '0%';

      let post_finish_rate = 0;

      let views = numbro(post.play_count).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      let likes = numbro(post.digg_count).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      let comments = Math.trunc(post.comment_count);
      let shares = numbro(post.share_count).format({
        thousandSeparated: true,
        mantissa: 0,
      });

      let date = moment(postData.video_create_time).format('D MMM, YYYY');

      let forecast = calculateForecast(sec, post.finish_rate);

      const totalHours = numbro(post.total_duration / (1000 * 60 * 60)).format({
        thousandSeparated: true,
        mantissa: 2,
      });
      let totalViews = numbro(postData.currentStats?.views || 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      let totalLikes = numbro(postData.currentStats?.likes || 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      let totalComments = numbro(postData.currentStats?.comments || 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });
      let totalShares = numbro(postData.currentStats?.shares || 0).format({
        thousandSeparated: true,
        mantissa: 0,
      });

      switch (platform?.toLowerCase()) {
        case EPlatform[EPlatform.tiktok]: {
          videoUrl = `${TIKTOK_LINK}@${CHANNEL_NAME}/video/${postData?.id}`;
          post_finish_rate = post.finish_rate * 100;
          break;
        }
        case EPlatform[EPlatform.instagram]: {
          videoUrl = postData?.video_url;
          post_finish_rate = post.finish_rate * 0.1;
          break;
        }
        case EPlatform[EPlatform.youtube]: {
          videoUrl = postData?.video_url;
          // post_finish_rate = post.finish_rate * 0.0001;
          post_finish_rate = post.finish_rate * 0.01;
          // forecast = calculateForecast(sec, post.finish_rate * 0.000001);
          forecast = calculateForecast(sec, post.finish_rate * 0.01);
          break;
        }
      }

      return {
        imageUrl,
        title,
        videoUrl,
        er,
        forecast,
        tags: postData.tags || '',
        sec,
        fin: post_finish_rate.toFixed(2) + '%',
        date,
        followers,
        views,
        likes,
        comments,
        shares,
        totalViews,
        totalLikes,
        totalComments,
        totalShares,
        totalHours,
        postId,
        platform,
      };
    }),
  );

  return stats.filter(Boolean);
};

const calculateForecast = (duration: number, finish: number): string => {
  const min = numbro(
    Math.max(
      0,
      finish * duration * ECoefficient.COEFFICIENT1 - ECoefficient.COEFFICIENT2,
    ),
  ).format({ average: true });
  const max = numbro(
    finish * duration * ECoefficient.COEFFICIENT3 + ECoefficient.COEFFICIENT4,
  ).format({ average: true });
  return `${min} - ${max}`;
};
