import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_QUERY } from '../models';
import { INSTAGRAM_COMMENTS } from '@core/config/const';

export const instagramCommentsAPI = createApi({
  reducerPath: INSTAGRAM_COMMENTS,
  tagTypes: ['Instagram_comments'],
  baseQuery: BASE_QUERY,
  endpoints: builder => ({
    getInstagramCommentById: builder.query<any, any>({
      query: params => ({
        url: INSTAGRAM_COMMENTS,
        params: {
          ...params,
          platform: 'instagram',
        },
      }),
      providesTags: ['Instagram_comments'],
    }),
    getInstagramComments: builder.query<any, any>({
      query: params => ({
        url: `${INSTAGRAM_COMMENTS}/all`,
        params: {
          ...params,
          platform: 'instagram',
        },
      }),
      providesTags: ['Instagram_comments'],
    }),
    postInstagramComment: builder.mutation<any, any>({
      query: params => ({
        url: `${INSTAGRAM_COMMENTS}/post`,
        params: {
          ...params,
          platform: 'instagram',
        },
      }),
      invalidatesTags: ['Instagram_comments'],
    }),
  }),
});

export const {
  useGetInstagramCommentByIdQuery,
  useGetInstagramCommentsQuery,
  usePostInstagramCommentMutation,
} = instagramCommentsAPI;
